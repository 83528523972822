<template>
  <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
    <h1 class="mb-3">Segurança</h1>
    <b-alert variant="success" show>
      <div v-if="confirmaAtualizacao" class="alert-body">
        <span><strong class="color-msg-success">Senha redefinida com sucesso! </strong></span>
      </div>
    </b-alert>
    <b-alert variant="danger" show>
      <div v-if="erros != null" class="alert-body">
        <strong>Registro não efetuado </strong>
        <ul id="array-with-index">
          <li>
            {{ erros }}
          </li>
        </ul>
      </div>
    </b-alert>
    <div class="mb-1">
      <validation-observer ref="simpleRules">
        <b-form id="SenhaForm" ref="SenhaForm" @submit.prevent="submit()">
          <div class="formulario" id="senhas">
            <h3>Redefina sua senha</h3>

            <b-row>
              <b-col md="6" xl="6" class="mb-1">
                <validation-provider
                  name="Senha Atual"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group label="Senha Atual" label-for="senha-atual">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        :type="tipo.atual"
                        v-model="senha.atual"
                        id="atual"
                        name="senha-atual"
                        :disabled="formDisabled.atual"
                        placeholder="******"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.atual"
                          @click="mostraSenha('atual')"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small v-if="errors.length > 0" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Nova Senha"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group label="Nova Senha" label-for="senha-nova">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        :type="tipo.nova"
                        v-model="senha.nova"
                        id="nova"
                        name="senha-nova"
                        @input="verificaSenha"
                        :disabled="formDisabled.nova"
                        placeholder="******"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.nova"
                          @click="mostraSenha('nova')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="validacao.regras" class="text-danger"
                      >{{
                        "A senha digitada não está de acordo com as regras ao lado."
                      }}<br
                    /></small>
                    <small v-if="errors.length > 0" class="text-danger">
                      {{ "Campo Obrigatório" }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  name="Confirme nova Senha"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Confirme a nova Senha"
                    label-for="senha-confirma"
                  >
                    <b-input-group
                      :state="errors.length > 0 ? false : null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        :type="tipo.confirma"
                        v-model="senha.confirma"
                        id="confirma"
                        @input="verificaSenha"
                        name="senha-confirma"
                        :disabled="formDisabled.confirma"
                        placeholder="******"
                        autocomplete="off"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="icone.confirma"
                          @click="mostraSenha('confirma')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="validacao.confirmaNaoConfere"
                      class="text-danger"
                      >{{
                        "O campo confirmação de senha deve ser igual ao campo nova senha."
                      }}<br
                    /></small>
                    <small v-if="errors.length > 0" class="text-danger">
                      {{ "Campo Obrigatório" }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="6" class="mb-1">
                <ul class="regras">
                  <b-row class="itens"
                    ><feather-icon
                      icon="CheckCircleIcon"
                      size="16"
                      class="mr-75 icone"
                    />Sua senha deve conter entre 8 e 16 caracteres</b-row
                  >

                  <b-row>
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="16"
                      class="mr-75 icone"
                    />
                    Utilize ao menos duas das seguintes opções:
                  </b-row>
                  <b-row class="itens">
                    <ul>
                      <li>Letra Maiúscula</li>
                      <li>Letra Minúscula</li>
                      <li>Número</li>
                      <li>Caracte Especial</li>
                    </ul>
                  </b-row>
                  <b-row class="itens">
                    <feather-icon
                      icon="SlashIcon"
                      size="16"
                      class="mr-75 icone"
                    />Não Utilize caracteres com acento</b-row
                  >
                </ul>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" align="center">
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  class="mr-1"
                  @click.prevent="limparCampos"
                >
                  Desfazer
                </b-button>

                <b-button
                  type="submit"
                  variant="custom"
                  @click.prevent="submit"
                >
                  Salvar
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
import {
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BForm,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver} from "vee-validate";
import { required, between, min, max, length, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { validation } from "@core/mixins/validation/validation";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BForm,
    BAlert,
    ValidationProvider,
    ValidationObserver,

  },

  mixins: [togglePasswordVisibility, validation],

  data() {
    return {
      required,
      between,
      min,
      max,
      length,
      email,
      erros: null,
      loadingShow: false,
      confirmaAtualizacao: false,
      DadosUsuario: {},
      validacao: {
        confirmaNaoConfere: false,
        regras: false,
      },
      formDisabled: {
        atual: false,
        nova: false,
        confirma: false,
      },
      tipo: {
        atual: "password",
        nova: "password",
        confirma: "password",
      },
      senha: {
        atual: "",
        nova: "",
        confirma: "",
      },
      icone: {
        atual: "EyeOffIcon",
        nova: "EyeOffIcon",
        confirma: "EyeOffIcon",
      },
    };
  },
  created() {
    this.DadosUsuario = getUserData();
  },

  methods: {
    mostraSenha(campo) {
      if (campo == "atual") {
        this.tipo.atual = this.tipo.atual === "password" ? "text" : "password";
        this.icone.atual =
          this.tipo.atual === "password" ? "EyeOffIcon" : "EyeIcon";
      }
      if (campo == "nova") {
        this.tipo.nova = this.tipo.nova === "password" ? "text" : "password";
        this.icone.nova =
          this.tipo.nova === "password" ? "EyeOffIcon" : "EyeIcon";
      }
      if (campo == "confirma") {
        this.tipo.confirma =
          this.tipo.confirma === "password" ? "text" : "password";
        this.icone.confirma =
          this.tipo.confirma === "password" ? "EyeOffIcon" : "EyeIcon";
      }
    },

    limparCampos() {
      this.senha.atual = "";
      this.senha.nova = "";
      this.senha.confirma = "";
      this.confirmaAtualizacao = false;
      this.validacao.confirmaNaoConfere = false;
      this.validacao.regras = false;
      this.$refs.simpleRules.reset();
    },
    verificaSenha() {
      this.validacao.confirmaNaoConfere =
        this.senha.confirma != this.senha.nova;

      this.validacao.regras = !this.passwordValidate(this.senha.nova);
      return this.validacao.confirmaNaoConfere || this.validacao.regra;
    },
    async AtualizarSenha() {
      let form = {
        password: this.senha.atual,
        newPassword: this.senha.nova,
        passwordConfirmation: this.senha.confirma,
      };

      this.loadingShow = true;
      try {
        await this.$http
          .patch(this.$api.RedefinirSenhaEmpresa, form)
          .then((res) => {
            if (res.status == 200) {
              this.confirmaAtualizacao = true;
              this.erros = null;
              this.loadingShow = false;
            }
          });
      } catch (error) {
        this.confirmaAtualizacao = false;
        this.loadingShow = false;
        this.erros = error.response.data.error
          ? error.response.data.error
          : error.response.data.errors;
      }
    },
    submit() {
      if (this.verificaSenha()) {
        return;
      }
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.AtualizarSenha();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icone {
  color: #2772c0;
}
.regras {
  /* Body / Paragraph */
  margin-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  /* or 150% */

  /* 1) Theme Color / Body - Text */

  color: #6e6b7b;

  li {
    line-height: 25px;
  }
  .itens {
    margin-bottom: 10px;
  }
}
</style>
