<template>
  <b-overlay :show="loadingShow" rounded="lg" opacity="1.0">
    <div>
      <h1 class="mb-3">Notificações</h1>
      <b-alert variant="success" show>
        <div v-if="confirmaAtualizacao" class="alert-body">
          <span
            ><strong class="color-msg-success"
              >Dados atualizados com sucesso!
            </strong></span
          >
        </div>
      </b-alert>
      <b-alert variant="danger" show>
        <div v-if="erros != null" class="alert-body">
          <strong>Registro não efetuado </strong>
          <ul id="array-with-index">
            <li>
              {{ erros }}
            </li>
          </ul>
        </div>
      </b-alert>
      <section class="tabela-notificacoes">
        <b-table
          id="tableNotificacoes"
          responsive="sm"
          no-border-collapse
          sticky-header="380px"
          :busy.sync="tabela.tabelaOcupada"
          :no-local-sorting="false"
          :fields="tabela.campos"
          :items="tabela.itens"
          show-empty
          empty-text="Não há registros para mostrar."
        >
          <template #cell(notificacao)="data">
            <strong> {{ data.item.descricao }}</strong>
          </template>

          <template
            v-for="campo in tabela.formaNotificacao"
            v-slot:[`cell(${campo.key})`]="item"
          >
            <b-form-checkbox
              :disabled="
                verificaFormaTipoNotificacaoValido(
                  item.item.id_tipo_notificacao,
                  item.field.key
                )
              "
              :key="campo.descricao"
              v-model="valorCheckBox"
              :value="item.item.id_tipo_notificacao + '-' + item.field.key"
            >
            </b-form-checkbox>
          </template>
        </b-table>
      </section>
      <b-row>
        <b-col md="12" align="center">
          <b-button
            type="reset"
            variant="outline-primary"
            class="mr-1"
            @click.prevent="desfazer"
          >
            Desfazer
          </b-button>

          <b-button type="submit" variant="custom" @click.prevent="submit">
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BAlert,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BButton,
    BTable,
    BFormCheckbox,
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BAlert,
  },
  data() {
    return {
      usuarioLogado: this.$store.state.sessions.userData,
      hasPerfilSaude: false,
      loadingShow: false,
      confirmaAtualizacao: false,
      erros: null,
      valorCheckBox: [],
      DadosUsuario: {},
      FormaNotificacaoTipoNotificacao: {},
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: "",
        campoOrdenado: "",
        campos: [],
        itens: [],
        formaNotificacao: [],
      },
    };
  },
  mounted() {
    this.buscaFormaNotificacaoTipoNotificacao();
    this.buscaNotificacoesUsuario();
    this.setHasSaude();
  },

  methods: {
    desfazer() {
      this.buscaNotificacoesUsuario();
      this.confirmaAtualizacao = false;
      this.erros = null;
    },
    buscaTipoNotificacao() {
      try {
        this.$http.get(this.$api.TipoNotificacao).then((res) => {
          if (res.status == 200) {
            this.tabela.itens = res.data;
          }
        });
      } catch (error) {
        this.loadingShow = false;
      }
    },

    groupBy(variavel) {
      var saida = [];

      for (var i = 0; i < variavel.length; i++) {
        var FNIgual = false;
        for (var j = 0; j < i; j++) {
          if (saida[j] && variavel[i].TN == saida[j].TN) {
            saida[j].FN.push(variavel[i].FN);
            FNIgual = true;
            break;
          }
        }

        if (!FNIgual) {
          saida.push({
            TN: variavel[i].TN,
            FN: [variavel[i].FN],
          });
        }
      }

      return saida;
    },
    FormataDadosFormulario() {
      let aux = [];
      let retorno = [];
      this.valorCheckBox.map((item) => {
        aux = item.split("-");
        retorno.push({ TN: aux[0], FN: aux[1] });
      });
      let agrupado = this.groupBy(retorno);

      retorno = {
        notificacoes: agrupado,
      };

      return retorno;
    },
    submit() {
      this.loadingShow = true;
      let form = this.FormataDadosFormulario();
      try {
        this.$http.post(this.$api.InsereNotificacoes, form).then((res) => {
          if (res.status == 200) {
            this.confirmaAtualizacao = true;
            this.erros = null;

            this.buscaNotificacoesUsuario();
          }
        });
      } catch (error) {
        this.loadingShow = false;
        this.erros = error.response.data.error
          ? error.response.data.error
          : error.response.data.errors;
      }
    },
    buscaNotificacoesUsuario() {
      this.loadingShow = true;
      try {
        this.valorCheckBox = [];
        this.$http.get(this.$api.NotificacoesUsuario).then((res) => {
          if (res.status == 200) {
            res.data.forEach((usrNot) => {
              this.valorCheckBox.push(
                usrNot.tipo_notificacao_forma_notificacao.id_tipo_notificacao +
                  "-" +
                  usrNot.tipo_notificacao_forma_notificacao.id_forma_notificacao
              );
            });

            this.loadingShow = false;
          }
        });
      } catch (error) {
        this.loadingShow = false;
      }
    },
    buscaFormaNotificacao() {
      try {
        this.$http.get(this.$api.FormaNotificacao).then((res) => {
          if (res.status == 200) {
            res.data.forEach((Forma) => {
              this.tabela.formaNotificacao.push({
                key: Forma.id_forma_notificacao.toString(),
                label: Forma.descricao,
              });
              this.tabela.campos.push({
                key: Forma.id_forma_notificacao.toString(),
                label: Forma.descricao,
              });
            });
          }
        });
      } catch (error) {
        this.loadingShow = false;
      }
    },
    setHasSaude(){
      const { role } = this.usuarioLogado;
      this.hasPerfilSaude = role.perfil.some((item) => item.id_perfil === 5);
    },
    verificaFormaTipoNotificacaoValido(Tipo, Forma) {
      if(Tipo === 3 || Tipo === 11){
        return true;
      }
      let result = this.FormaNotificacaoTipoNotificacao.find(
        (item) =>
          item.id_forma_notificacao == Forma && item.id_tipo_notificacao == Tipo
      );
      return result ? false : true;
    },
    buscaFormaNotificacaoTipoNotificacao() {
      try {
        this.$http
          .get(this.$api.FormaNotificacaoTipoNotificacao)
          .then((res) => {
            if (res.status == 200) {
              this.FormaNotificacaoTipoNotificacao = res.data;
              this.verificaFormaTipoNotificacaoValido(1, 1);
            }
          });
      } catch (error) {
        this.loadingShow = false;
      }
    },
  },

  created() {
    this.DadosUsuario = getUserData();

    this.tabela.campos = [
      { key: "notificacao", label: "Notificação", sortable: true },
    ];
    this.buscaTipoNotificacao();
    this.buscaFormaNotificacao();
  },
};
</script>

