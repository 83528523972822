<template>
  <b-overlay :show="loadingShow" rounded="lg" opacity="0.6">
    <div id="minha-conta" class="bg-white">
      <!-- header -->
      <div id="header">
        <div class="background" :style="`background: url(${background})`">
          <div id="component-profile">
            <div class="profile-header">
              <div class="profile-img-container d-flex align-items-center">
                <div class="profile-img">
                  <span class="b-avatar align-baseline badge-custom rounded-0">
                    <span class="b-avatar-text">
                      <span v-text="Usuario.fullName.substr(0, 1)"></span></span
                  ></span>
                </div>
                <div class="profile-title ml-3">
                  <h2 class="text-white" v-text="Usuario.fullName"></h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- tabs -->
        <div class="tabs">
          <b-nav tabs align="center">
            <div v-show="rota.visible" v-for="rota in rotas" :key="rota.para">
              <b-nav-item
                :to="getRotaParametros(rota.para)"
                :active="rota.para == $route.name"
                @click="setaComponenteAtual(rota.componente)"
              >
                {{ rota.titulo }}
              </b-nav-item>
            </div>
          </b-nav>
        </div>
        <!-- tabs -->
      </div>
      <!-- header -->

      <section id="conteudo">
        <div class="tab-content">
          <!-- notificacoes -->
          <b-col md="12"> </b-col>
          <!-- notificacoes -->

          <b-card>
            <component
              :is="currentTabComponent"
              v-if="!loadingShow"
            ></component>
          </b-card>
        </div>

        <!-- modal cadastro/edição empresa -->
      </section>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BNav,
  BNavItem,
  BSpinner,
  BOverlay,
  BAlert,
  BRow,
  BCol,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ProfileHeader from "@/views/pages/profile/ProfileHeader.vue";
import MeusDados from "@/views/pages/minha-conta/MeusDados.vue";
import Seguranca from "@/views/pages/minha-conta/Seguranca.vue";
import Notificacao from "@/views/pages/minha-conta/Notificacoes.vue";
import {getUserData} from "@/auth/utils";

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BNav,
    BNavItem,
    BSpinner,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BNavItemDropdown,
    BDropdownItem,
    ProfileHeader,
    MeusDados,
    Seguranca,
    Notificacao,
  },
  data() {
    return {
      currentTab: null,
      pessoa: null,
      usuario: null,
      empresa: null,
      url: null,
      loadingShow: false,
      background: require("@/assets/images/backgrounds/minha-conta.svg"),

      rotas: [
        {
          titulo: "Meus Dados",
          para: "meusDados",
          componente: "MeusDados",
          visible: this.$can('VISUALIZAR','MENU_SUPERIOR_MINHA_CONTA'),
        },
        {
          titulo: "Segurança",
          para: "seguranca",
          componente: "Seguranca",
          visible: this.$can('VISUALIZAR','MENU_SUPERIOR_MINHA_CONTA'),
        },
        {
          titulo: "Notificações",
          para: "notificacoes",
          componente: "Notificacao",
          visible: this.$can('VISUALIZAR','MENU_SUPERIOR_NOTIFICACOES'),
        },
      ],
    };
  },

  created() {
    const usuario = getUserData();
    this.pessoa = usuario.pessoa;
    this.Usuario = usuario;
    this.Empresa = usuario.empresa;
  },
  beforeMount() {
    this.setaComponenteAtual();
  },
  methods: {
    setaComponenteAtual(componente = null) {
      let itemComponente = this.rotas.find(
        (rota) => rota.para === this.$route.name
      );
      this.currentTab = componente ? componente : itemComponente.componente;
    },

    getRotaParametros(tab) {
      return {
        name: tab,
        params: {
          idGrupo: "",
        },
      };
    },
  },
  computed: {
    currentTabComponent() {
      return this.currentTab;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-profile.scss";

#minha-conta #header {
  margin-bottom: 20px;

  .background {
    border-top-left-radius: 5px 5px;
    border-top-right-radius: 5px 5px;
    height: 136px;
    background-repeat: no-repeat;
    background-color: #7b00cd;

    .avatar {
      height: 117px;
      width: 117px;
      background-color: #002060;

      span {
        font-size: 57px;
      }
    }
  }
  .tabs {
    background-color: $white;
    padding: 11px 0;
    border-bottom-left-radius: 5px 5px;
    border-bottom-right-radius: 5px 5px;

    .nav.nav-tabs {
      margin: 0;
      padding: 0;

      .nav-link {
        background: $white;
        color: #5e5873;
      }
      .nav-link.active {
        background: $custom-purple;
        border-color: $custom-purple;
        color: $white;
        border-radius: 0.358rem;
      }
      .nav-link:after {
        display: none;
      }
    }
  }
}

#component-profile .profile-header .profile-img-container {
  bottom: 0;
  position: unset;
  margin: 40px 0 0 40px;
}

@media only screen and (max-width: 823px) {
  #component-profile .profile-header .profile-img-container {
    margin-top: 20px;

    .profile-img {
      height: 6.92rem;
      width: 6.92rem;
    }
  }
}
</style>
